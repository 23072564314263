import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import MainImageWrapper from "./mainImage.style"

const MainImage = ({ imageWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "image/elegant/about/about.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <MainImageWrapper>
      <Container width="1402px">
        <Fade up delay={100}>
          <Box {...imageWrapper}>
            <Image
              fluid={
                (Data.featureImage !== null) | undefined
                  ? Data.aboutImage.childImageSharp.fluid
                  : {}
              }
              alt="about image"
            />
          </Box>
        </Fade>
      </Container>
    </MainImageWrapper>
  )
}

// MainImage style props
MainImage.propTypes = {
  imageWrapper: PropTypes.object,
}

// MainImage default style
MainImage.defaultProps = {
  imageWrapper: {
    display: " block",
  },
}

export default MainImage
