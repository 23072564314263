import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import {
  ContentWrapperFirst,
  ContentWrapperSecond,
  ImageWrapper,
} from "./aboutMe.style"

const AboutMe = ({
  rowSecond,
  rowFirst,
  colImage,
  colText,
  sectionWrapper,
}) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      elegantJson {
        aboutMeFirstSection {
          heading
          text
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        aboutMeSecondSection {
          heading
          text
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const contentSecond = data.elegantJson.aboutMeSecondSection
  const contentFirst = data.elegantJson.aboutMeFirstSection

  return (
    <Box {...sectionWrapper} as="section">
      <Container mobileGutter width="1402px">
        {/* First Part */}
        <Box className="row" {...rowFirst}>
          <Box className="col" {...colText}>
            <ContentWrapperFirst>
              <Heading content={contentFirst.heading} />
              <Fade up delay={300}>
                <Text content={contentFirst.text} />
              </Fade>
            </ContentWrapperFirst>
          </Box>
          <Box className="col" {...colImage}>
            <Fade up delay={100}>
              <ImageWrapper>
                <Image
                  fluid={
                    (contentFirst.image !== null) | undefined
                      ? contentFirst.image.childImageSharp.fluid
                      : {}
                  }
                  alt="about image"
                />
              </ImageWrapper>
            </Fade>
          </Box>
        </Box>
        {/* Second Part */}
        <Box {...rowSecond}>
          <Box className="col" {...colImage}>
            <Fade up delay={100}>
              <ImageWrapper>
                <Image
                  fluid={
                    (contentSecond.image !== null) | undefined
                      ? contentSecond.image.childImageSharp.fluid
                      : {}
                  }
                  alt="about image"
                />
              </ImageWrapper>
            </Fade>
          </Box>
          <Box className="col" {...colText}>
            <ContentWrapperSecond>
              <Fade up delay={300}>
                <Heading content={contentSecond.heading} />
                <Text content={contentSecond.text} />
              </Fade>
            </ContentWrapperSecond>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

// AboutMe style props
AboutMe.propTypes = {
  rowFirst: PropTypes.object,
  rowSecond: PropTypes.object,
  colImage: PropTypes.object,
  colText: PropTypes.object,
  sectionWrapper: PropTypes.object,
}

// AboutMe default style
AboutMe.defaultProps = {
  sectionWrapper: {
    pt: ["0px", "32px", "32px"],
    pb: ["56px", "56px", "160px"],
    position: "relative",
  },
  // AboutMe row default style
  rowFirst: {
    flexBox: true,
    flexWrap: "wrap-reverse",
    ml: ["-15px", "-15px", "-15px", "-25px", "-25px"],
    mr: ["-15px", "-15px", "-15px", "-25px", "-25px"],
    mb: ["56px", "56px", "112px", "112px", "112px"],
  },
  rowSecond: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["-15px", "-15px", "-15px", "-25px", "-25px"],
    mr: ["-15px", "-15px", "-15px", "-25px", "-25px"],
  },
  // AboutMe col default style
  colImage: {
    width: [1, 1, 1, 1 / 2],
    pl: ["15px", "15px", "15px", "25px", "25px"],
    pr: ["15px", "15px", "15px", "25px", "25px"],
  },
  colText: {
    width: [1, 1, 1, 1 / 2],
    pl: ["15px", "15px", "15px", "25px", "25px"],
    pr: ["15px", "15px", "15px", "25px", "25px"],
    flexBox: true,
  },
}

export default AboutMe
