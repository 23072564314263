import Fade from "react-reveal/Fade"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import React, { useCallback, useState } from "react"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"

const ImageGallery = ({}) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const photos = [
    {
      src: `/images/shikha/1.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/2.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/3.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/4.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/5.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/6.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/7.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/8.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/9.jpeg`,
      width: 2,
      height: 3,
    },
    {
      src: `/images/shikha/10.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/11.jpeg`,
      width: 3,
      height: 4,
    },
    {
      src: `/images/shikha/12.jpeg`,
      width: 3,
      height: 4,
    },
  ]

  return (
    <Box as="section">
      <Container mobileGutter width="1402px">
        <Fade up delay={100}>
          <div>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </Fade>
      </Container>
    </Box>
  )
}

export default ImageGallery
