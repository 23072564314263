import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const ImageWrapper = styled.div`
  /* padding-right: 20px; */
  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`

export const ContentWrapperFirst = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 56px;
  z-index: 100;
  @media only screen and (max-width: 1220px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 0px;
  }

  h2 {
    position: absolute;
    margin-left: 8%;
    top: 220px;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 0.96px;
    margin-bottom: 0px;
    max-width: 700px;
    color: ${themeGet("colors.textColor", "#333333")};
    @media only screen and (max-width: 1440px) {
      font-size: 48px;
    }
    @media only screen and (max-width: 1220px) {
      position: relative;
      top: 30px;
      margin-right: 0px;
      margin-bottom: 24px;
      margin-left: 0px;
      font-size: 40px;
    }
    @media only screen and (max-width: 991px) {
      position: relative;
      font-size: 32px;
      margin-right: 0px;
      margin-top: 24px;
      top: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-top: 70%;
    margin-bottom: 0px;
    color: ${themeGet("colors.textColor", "#333333")};
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1220px) {
      position: relative;
      margin-right: 0px;
      margin-bottom: 24px;
      margin-top: 0px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 991px) {
      /* margin-bottom: 20px; */
    }
  }
`

export const ContentWrapperSecond = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 56px;
  z-index: 100;
  @media only screen and (max-width: 1220px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 0px;
  }

  h2 {
    margin-left: -22%;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 0.96px;
    margin-bottom: 0px;
    color: ${themeGet("colors.textColor", "#333333")};
    max-width: 760px;
    @media only screen and (max-width: 1440px) {
      font-size: 48px;
    }
    @media only screen and (max-width: 1220px) {
      margin-left: 0px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 32px;
      margin-left: 0px;
      margin-top: 24px;
    }


  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0px;
    color: ${themeGet("colors.textColor", "#333333")};
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 991px) {
      /* margin-bottom: 20px; */
    }
  }
`
