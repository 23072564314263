import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import { DetailsWrapper, Item } from "./doubleColumnText.style"

const DoubleColumnText = ({
  sectionWrapper,
  row,
  col,
  sectionTitle,
  sectionText,
  sectionTextB,
  aboutContent,
  header,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container mobileGutter width="1402px">
        <Box {...row}>
          <Box {...col}>
            <Item>
              <DetailsWrapper>
                {header ? (
                  <Fade up delay={100}>
                    <Heading
                      content={aboutContent.leftTextColumn}
                      {...sectionTitle}
                    />
                  </Fade>
                ) : (
                  <Fade up delay={100}>
                    <Text
                      content={aboutContent.leftTextColumn}
                      {...sectionText}
                    />
                  </Fade>
                )}
              </DetailsWrapper>
            </Item>
          </Box>
          <Box {...col}>
            <Item>
              <DetailsWrapper>
                <Fade up delay={100}>
                  <Text
                    content={aboutContent.rightTextColumn}
                    {...sectionText}
                  />
                </Fade>
                <Fade up delay={100}>
                  {aboutContent.rightTextColumn && (
                    <Text
                      content={aboutContent.rightTextColumnB}
                      {...sectionTextB}
                      className="additionalStyles"
                    />
                  )}
                </Fade>
              </DetailsWrapper>
            </Item>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

DoubleColumnText.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionText: PropTypes.object,
  sectionTextB: PropTypes.object,
  aboutContent: PropTypes.object,
  header: PropTypes.bool,
}

DoubleColumnText.defaultProps = {
  sectionWrapper: {
    pt: ["0px", "0px", "0px", "0px", "0px"],
    pb: ["56px", "56px", "90px"],
  },

  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["-15px", "-15px", "-15px", "-25px", "-25px"],
    mr: ["-15px", "-15px", "-15px", "-25px", "-25px"],
  },
  col: {
    width: [1, 1, 1, 1 / 2],
    pl: ["15px", "15px", "15px", "25px", "25px"],
    pr: ["15px", "15px", "15px", "25px", "25px"],
    // mb: ["30px", "30px", "30px", "50px", "50px"],
  },
  sectionTitle: {
    fontSize: ["32px", "32px", "48px"],
    fontWeight: "300",
    color: "#333333",
    letterSpacing: "0.96px",
    mb: ["24px", "24px", "0px"],
  },
  sectionTitleB: {
    fontSize: ["32px", "48px"],
    fontWeight: "300",
    color: "#333333",
    letterSpacing: "0.96px",
  },
  sectionText: {
    fontSize: ["16px", "16px"],
    fontWeight: "300",
    color: "#333333",
    lineHeight: "28px",
    mb: ["24px", "24px", "24px", "0px"],
    mt: ["0px", "0px"],
  },
}

export default DoubleColumnText
