import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import AboutMe from "../../containers/AboutMe"
import ChangeYourLife from "../../containers/ChangeYourLife"
import DoubleColumnText from "../../containers/DoubleColumnText"
import ImageGallery from "../../containers/ImageGallery"
import Layout from "../../containers/Layout"
import MainImage from "../../containers/MainImage"
import PageTitle from "../../containers/PageTitle"
import SEO from "../../components/SEO"
import { ContentWrapper } from "../../containers/elegant.style"

const AboutPage = ({ location }) => {
  const Data = useStaticQuery(graphql`
    query aboutData {
      allElegantJson {
        edges {
          node {
            aboutPage {
              leftTextColumn
              rightTextColumn
              rightTextColumnB
            }
          }
        }
      }
    }
  `)

  const aboutContent = Data.allElegantJson.edges[0].node.aboutPage

  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        url={url}
        title="About Elegant Beauty Clinic"
        shortDesc="Your Trusted Health & Aesthetic Clinic"
        description="Learn more about our clinics in Bournemouth and Poole. Book your consultation today and let Shikha create the best treatment plan for you and your skin."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle
            title="With over 12 years experience within the aesthetics industry, Shikha loves what she does and has a strong enthusiasm for providing high class treatments and procedures."
            subtitle="About Elegant"
          />
          <MainImage />
          <DoubleColumnText aboutContent={aboutContent[0]} />
          <AboutMe />
          <DoubleColumnText aboutContent={aboutContent[1]} header />
          <ImageGallery />
          <ChangeYourLife whiteBG />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default AboutPage
