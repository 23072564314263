import styled from "styled-components"

const MainImageWrapper = styled.section`
  padding: 90px 0;
  overflow: hidden;

  @media only screen and (max-width: 1440px) {
    padding: 90px 0;
  }
  @media only screen and (max-width: 1360px) {
    padding: 90px 0;
  }
  @media (max-width: 990px) {
    padding: 90px 0;
  }
  @media (max-width: 767px) {
    padding: 56px 0 56px 0;
  }
  @media (max-width: 480px) {
    padding: 56px 0 24px 0;
  }
`

export default MainImageWrapper
