import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const Item = styled.div`
  position: relative;
`

export const DetailsWrapper = styled.div`
  .additionalStyles {
    color: ${themeGet("colors.textColor", "#333333")};
    margin-top: 60px;
    @media (max-width: 991px) {
      margin-top: 24px;
    }
  }
`
